import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

import Bottleneck from 'bottleneck';
import { emptyResponse } from './utils';
import mapLimit from 'async/mapLimit';
import { queue, retry } from 'async';
import { message } from 'antd';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';

// Create a new rate limiter
const limiter = new Bottleneck({
  maxConcurrent: 100, // Allow up to 100 concurrent requests
  minTime: 50, // 50ms between requests (adjust to your API's capabilities)
  reservoir: 500, // Allow up to 500 requests in the first batch
  reservoirRefreshAmount: 500, // Refill 500 requests
  reservoirRefreshInterval: 60 * 1000, // Refill every 60 seconds (adjust to API rate limits)
});
limiter.on('failed', async (error, jobInfo) => {
  if (error.response && error.response.status === 429) {
    console.warn(`Rate limit hit. Retrying after ${jobInfo.retryCount} retries.`);
    if (jobInfo.retryCount < 3) {
      // Retry up to 3 times
      return 2000; // Retry after 2 seconds on 429 Too Many Requests error
    }
  }
  return null;
});

// Helper function to sort transactions by the latest message date
const sortedTransactionsByLatestMessage = txs =>
  reverse(
    sortBy(txs, tx => {
      const latestMessageDate =
        tx?.attributes?.latestMessageDate || tx?.attributes?.lastTransitionedAt;
      return latestMessageDate ? new Date(latestMessageDate).getTime() : null;
    })
  );

// ================ Action types ================ //

export const FETCH_MESSAGES_REQUEST = 'app/InboxPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/InboxPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/InboxPage/FETCH_MESSAGES_ERROR';

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_COMPLETED_ORDERS_REQUEST = 'app/InboxPage/FETCH_COMPLETED_ORDERS_REQUEST';
export const FETCH_COMPLETED_ORDERS_SUCCESS = 'app/InboxPage/FETCH_COMPLETED_ORDERS_SUCCESS';
export const FETCH_COMPLETED_ORDERS_ERROR = 'app/InboxPage/FETCH_COMPLETED_ORDERS_ERROR';

export const FETCH_SELECTED_ADMIN_USER_REQUEST = 'app/InboxPage/FETCH_SELECTED_ADMIN_USER_REQUEST';
export const FETCH_SELECTED_ADMIN_USER_SUCCESS = 'app/InboxPage/FETCH_SELECTED_ADMIN_USER_SUCCESS';
export const FETCH_SELECTED_ADMIN_USER_ERROR = 'app/InboxPage/FETCH_SELECTED_ADMIN_USER_ERROR';

export const FETCH_LISTING_REQUEST = 'app/InboxPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/InboxPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/InboxPage/FETCH_LISTING_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/InboxPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/InboxPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/InboxPage/CONFIRM_PAYMENT_ERROR';

export const GET_LISTING_COUNT_REQUEST = 'app/InboxPage/GET_LISTING_COUNT_REQUEST';
export const GET_LISTING_COUNT_SUCCESS = 'app/InboxPage/GET_LISTING_COUNT_SUCCESS';
export const GET_LISTING_COUNT_ERROR = 'app/InboxPage/GET_LISTING_COUNT_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchCompletedOrdersError: null,
  fetchOrdersOrSalesError: null,
  fetchMessagesError: null, // Add fetch error state for messages
  pagination: null,
  transactionRefs: [], // For orders and sales
  messageRefs: [], // For messages
  completedOrderRefs: [], // For completed orders
  fetchSelectedAdminUsersInProgress: false,
  fetchSelectedAdminUsersInError: null,
  selectedAdminUsers: [],

  fetchListingInProgress: false,
  fetchListingError: null,
  selectedUserListings: [],

  confirmPaymentInProgress: false,
  confirmPaymentSuccess: false,
  confirmPaymentError: null,
  orderDetails: [],

  getListingCountInProgress: false,
  getListingCountSuccess: false,
  getListingCountError: null,
  listings: [],
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COMPLETED_ORDERS_REQUEST:
    case FETCH_ORDERS_OR_SALES_REQUEST:
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchOrdersOrSalesError: null,
        fetchMessagesError: null,
        fetchCompletedOrdersError: null,
      };

    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions), // Orders or sales
        pagination: payload.data.meta,
      };
    }

    case FETCH_COMPLETED_ORDERS_SUCCESS: {
      const transactions = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        completedOrderRefs: entityRefs(transactions), // Completed orders
        pagination: payload.data.meta,
      };
    }

    case FETCH_MESSAGES_SUCCESS: {
      const transactions = sortedTransactionsByLatestMessage(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        messageRefs: entityRefs(transactions), // Messages
        pagination: payload.data.meta,
      };
    }

    case FETCH_ORDERS_OR_SALES_ERROR:
    case FETCH_COMPLETED_ORDERS_ERROR:
    case FETCH_MESSAGES_ERROR:
      //console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: type === FETCH_ORDERS_OR_SALES_ERROR ? payload : null,
        fetchCompletedOrdersError: type === FETCH_COMPLETED_ORDERS_ERROR ? payload : null,
        fetchMessagesError: type === FETCH_MESSAGES_ERROR ? payload : null,
      };

    case FETCH_SELECTED_ADMIN_USER_REQUEST:
      return {
        ...state,
        fetchSelectedAdminUsersInProgress: true,
        fetchSelectedAdminUsersInError: null,
      };
    case FETCH_SELECTED_ADMIN_USER_SUCCESS:
      return {
        ...state,
        selectedAdminUsers: payload.data,
        fetchSelectedAdminUsersInProgress: false,
      };
    case FETCH_SELECTED_ADMIN_USER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        fetchSelectedAdminUsersInProgress: false,
        fetchSelectedAdminUsersInError: payload,
      };

    case FETCH_LISTING_REQUEST:
      return { ...state, fetchListingInProgress: true, fetchListingError: null };
    case FETCH_LISTING_SUCCESS:
      return { ...state, selectedUserListings: payload.data, fetchListingInProgress: false };
    case FETCH_LISTING_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, fetchListingInProgress: false, fetchListingError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return {
        ...state,
        confirmPaymentInProgress: true,
        confirmPaymentSuccess: false,
        confirmPaymentError: null,
      };
    case CONFIRM_PAYMENT_SUCCESS:
      return {
        ...state,
        orderDetails: payload.data,
        confirmPaymentSuccess: true,
        confirmPaymentInProgress: false,
      };
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        confirmPaymentInProgress: false,
        confirmPaymentSuccess: false,
        confirmPaymentError: payload,
      };

    case GET_LISTING_COUNT_REQUEST:
      return {
        ...state,
        getListingCountInProgress: true,
        getListingCountSuccess: false,
        getListingCountError: null,
      };
    case GET_LISTING_COUNT_SUCCESS:
      return {
        ...state,
        listings: payload,
        getListingCountSuccess: true,
        getListingCountInProgress: false,
      };
    case GET_LISTING_COUNT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        getListingCountInProgress: false,
        getListingCountSuccess: false,
        getListingCountError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = response => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: response,
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

// Action creators for Completed Orders
const fetchCompletedOrdersRequest = () => ({ type: FETCH_COMPLETED_ORDERS_REQUEST });
const fetchCompletedOrdersSuccess = response => ({
  type: FETCH_COMPLETED_ORDERS_SUCCESS,
  payload: response,
});
const fetchCompletedOrdersError = e => ({
  type: FETCH_COMPLETED_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchSelectedAdminUsersRequest = () => ({ type: FETCH_SELECTED_ADMIN_USER_REQUEST });
const fetchSelectedAdminUsersSuccess = response => ({
  type: FETCH_SELECTED_ADMIN_USER_SUCCESS,
  payload: response,
});
const fetchSelectedAdminUsersError = e => ({
  type: FETCH_SELECTED_ADMIN_USER_ERROR,
  error: true,
  payload: e,
});

const fetchListingRequest = () => ({ type: FETCH_LISTING_REQUEST });
const fetchListingSuccess = response => ({
  type: FETCH_LISTING_SUCCESS,
  payload: response,
});
const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });
const confirmPaymentSuccess = response => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: response,
});
const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const getListingCountRequest = () => ({ type: GET_LISTING_COUNT_REQUEST });
const getListingCountSuccess = response => ({
  type: GET_LISTING_COUNT_SUCCESS,
  payload: response,
});
const getListingCountError = e => ({
  type: GET_LISTING_COUNT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadCompletedOrdersData = (params, search, isAdmin) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCompletedOrdersRequest());

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[params.tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${params.tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10;
  const transitions = getAllTransitionsForEveryProcess();
  const orderTransitions = transitions.filter(
    t =>
      t !== 'transition/inquire' &&
      t !== 'transition/expire-payment' &&
      t !== 'transition/cancel-skip-payment'
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  try {
    // Step 1: Get total number of pages
    let numberOfPages = 1;

    var allTransactions;
    var allIncluded;

    if (isAdmin && params.tab === 'sales' && params.subTab === 'completedOrders') {
      const apiQueryParams = {
        lastTransitions: orderTransitions,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'messages',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };
      const transactionBatches = await fetch('/api/get-all-completedtransactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(apiQueryParams),
      })
        .then(response => response.json())
        .then(async res => {
          return res;
        })
        .catch(e => {
          dispatch(fetchSelectedAdminUsersError(storableError(e)));
        });
      allTransactions = transactionBatches.data.data;
      allIncluded = transactionBatches.data.included;
    } else {
      const numberOfPagesQuery = {
        only: onlyFilter,
        // include: [
        //   'customer',
        //   'customer.profileImage',
        //   'provider',
        //   'provider.profileImage',
        //   'listing',
        //   'listing.currentStock',
        //   'messages',
        //   'listing.images',
        // ],
        // 'fields.transaction': [
        //   'processName',
        //   'lastTransition',
        //   'lastTransitionedAt',
        //   'transitions',
        //   'payinTotal',
        //   'payoutTotal',
        //   'lineItems',
        //   'metadata',
        //   'protectedData',
        // ],
        // 'fields.listing': [
        //   'title',
        //   'availabilityPlan',
        //   'publicData.listinglocation',
        //   'publicData.location',
        // ],
        // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
        include: [],
        page: 1,
        perPage: 1,
      };

      const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
      numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;

      if (numberOfPages === 0) {
        dispatch(addMarketplaceEntities(emptyResponse));
        dispatch(fetchCompletedOrdersSuccess(emptyResponse));
        return emptyResponse;
      }
      // Step 2: Fetch transactions in batches
      const getTransactions = async page => {
        const apiQueryParams = {
          only: onlyFilter,
          lastTransitions: orderTransitions,
          include: [
            'customer',
            'customer.profileImage',
            'provider',
            'provider.profileImage',
            'listing',
            'listing.currentStock',
            'messages',
            'listing.images',
          ],
          'fields.transaction': [
            'processName',
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'lineItems',
            'metadata',
            'protectedData',
          ],
          'fields.listing': [
            'title',
            'availabilityPlan',
            'publicData.listinglocation',
            'publicData.location',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          page: page + 1,
          perPage: 100,
        };

        return await sdk.transactions.query(apiQueryParams);
      };

      const transactionBatches = await mapLimit(
        [...Array(numberOfPages).keys()],
        5,
        getTransactions
      );

      // // Step 3: Combine all transactions and included data
      // const allTransactions = transactionBatches
      //   .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
      //   .filter(tx => new Date(tx.attributes.protectedData.orderData?.getbyDate) < today);

      // Step 3: Combine all transactions and included data
      allTransactions = transactionBatches
        .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
        .filter(tx => {
          const getbyDate = new Date(tx.attributes.protectedData.orderData?.getbyDate);
          const lastTransition = tx.attributes.lastTransition;

          // Include transactions where getbyDate is before today OR the last transition is "transition/mark-delivered"
          return (
            getbyDate < today ||
            (params.tab === 'sales' && lastTransition === 'transition/mark-delivered') ||
            (params.tab === 'orders' && lastTransition === 'transition/mark-received')
          );
        });

      allIncluded = transactionBatches.reduce((a, txb) => a.concat(txb?.data?.included || []), []);
    }

    // Step 4: Sort transactions by getbyDate
    const sortedTransactions = allTransactions.sort((a, b) => {
      const dateA = new Date(a?.attributes?.protectedData?.orderData?.getbyDate);
      const dateB = new Date(b?.attributes?.protectedData?.orderData?.getbyDate);
      return dateB - dateA;
    });

    // Step 5: Apply pagination
    const paginatedTransactions = sortedTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(sortedTransactions.length / perPage);

    const finalResponse = {
      status: 200,
      statusText: '',
      data: {
        data: sortedTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: perPage,
          totalItems: sortedTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));
    dispatch(fetchCompletedOrdersSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchCompletedOrdersError(storableError(error)));
    throw error;
  }
};

export const loadMessagesData = (params, search, isAdmin) => async (dispatch, getState, sdk) => {
  dispatch(fetchMessagesRequest());
  const { subTab, tab } = params;
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10; // Number of transactions per page

  try {
    // Step 1: Fetch transactions in batches until all data is retrieved

    let numberOfPages = 1;

    var allTransactions;
    var allIncluded;

    if (isAdmin && tab === 'sales' && subTab === 'inquiries') {
      const apiQueryParams = {
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'messages',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };
      const transactionBatches = await fetch('/api/get-all-messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(apiQueryParams),
      })
        .then(response => response.json())
        .then(async res => {
          return res;
        })
        .catch(e => {
          dispatch(fetchSelectedAdminUsersError(storableError(e)));
        });
      allTransactions = transactionBatches.data.data;
      allIncluded = transactionBatches.data.included;
    } else {
      const numberOfPagesQuery = {
        only: onlyFilter,
        // include: [
        //   'customer',
        //   'customer.profileImage',
        //   'provider',
        //   'provider.profileImage',
        //   'listing',
        //   'listing.currentStock',
        //   'messages',
        //   'listing.images',
        // ],
        // 'fields.transaction': [
        //   'processName',
        //   'lastTransition',
        //   'lastTransitionedAt',
        //   'transitions',
        //   'payinTotal',
        //   'payoutTotal',
        //   'lineItems',
        //   'metadata',
        //   'protectedData',
        // ],
        // 'fields.listing': [
        //   'title',
        //   'availabilityPlan',
        //   'publicData.listinglocation',
        //   'publicData.location',
        // ],
        // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
        include: [],
        page: 1,
        perPage: 1,
      };

      const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
      numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;

      if (numberOfPages === 0) {
        dispatch(addMarketplaceEntities(emptyResponse));
        dispatch(fetchMessagesSuccess(emptyResponse));
        return emptyResponse;
      }
      const getTransactions = async page => {
        const apiQueryParams = {
          only: onlyFilter,
          // include: ['listing', 'provider', 'customer', 'booking', 'messages'],
          include: [
            'customer',
            'customer.profileImage',
            'provider',
            'provider.profileImage',
            'listing',
            'listing.currentStock',
            'messages',
            'listing.images',
          ],
          'fields.transaction': [
            'processName',
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'lineItems',
            'metadata',
            'protectedData',
          ],
          'fields.listing': [
            'title',
            'availabilityPlan',
            'publicData.listinglocation',
            'publicData.location',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          page: page + 1,
          perPage: 100, // Large batch size to reduce the number of calls
        };

        return await sdk.transactions.query(apiQueryParams);
      };
      const transactionBatches = await mapLimit(
        [...Array(numberOfPages).keys()],
        5,
        getTransactions
      );

      allTransactions = transactionBatches
        .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
        .filter(tx => {
          return (
            tx.relationships &&
            tx.relationships.messages &&
            tx.relationships.messages.data.length > 0
          );
        });

      allIncluded = transactionBatches.reduce((a, txb) => a.concat(txb?.data?.included || []), []);
    }

    const allIncludedMessages = allIncluded.filter(i => i.type === 'message');
    const messageIdCreatedAtMap = {};
    for (const t of allIncludedMessages) {
      messageIdCreatedAtMap[t?.id?.uuid] = t?.attributes?.createdAt;
    }

    const transactionsWithMessages = allTransactions.map(transaction => {
      const messageIds = transaction?.relationships?.messages?.data?.map(
        message => message?.id?.uuid
      );
      const createdAtDates = messageIds
        .map(message => messageIdCreatedAtMap[message])
        .sort(function(a, b) {
          return new Date(b) - new Date(a);
        });

      return {
        ...transaction,
        attributes: {
          ...transaction.attributes,
          latestMessageDate: createdAtDates[0],
        },
      };
    });

    // Step 3: Remove transactions that do not have messages
    const filteredTransactions = transactionsWithMessages.filter(tx => tx !== null);

    // Step 4: Sort transactions globally by the latest message date
    const sortedTransactions = sortedTransactionsByLatestMessage(filteredTransactions);

    // Step 5: Apply pagination to the sorted list
    const paginatedTransactions = sortedTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(sortedTransactions.length / perPage);

    // transactionResponse.data.data = paginatedTransactions;
    // transactionResponse.data.meta = {
    //   page: page,
    //   perPage: perPage,
    //   totalItems: sortedTransactions.length,
    //   totalPages: totalPages,
    // }
    const finalResponse = {
      status: 200, // You can adjust this if needed
      statusText: '', // Add any meaningful text if necessary
      data: {
        data: sortedTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: 10,
          totalItems: sortedTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));

    dispatch(fetchMessagesSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchMessagesError(storableError(error)));
    throw error;
  }
};

const getConfirmPaymentDate = transaction => {
  const confirmPayment = transaction.attributes.transitions.find(
    t => t.transition === 'transition/confirm-payment'
  );
  return confirmPayment ? new Date(confirmPayment.createdAt) : null;
};

const getSkipPaymentDate = transaction => {
  const skipPayment = transaction.attributes.transitions.find(
    t => t.transition === 'transition/skip-payment'
  );
  return skipPayment ? new Date(skipPayment.createdAt) : null;
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const { tab, subTab, sortFilter } = params;

  const admAccessEmail = process.env.REACT_APP_ADMIN_EMAIL;
  const admAccessEmailArr = admAccessEmail.split(' ');
  const currentUser = await dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    return currentUser;
  });
  const currentUserEmail = currentUser.attributes.email;
  const isAdmin = admAccessEmailArr.includes(currentUserEmail);

  if (subTab === 'inquiries') {
    return dispatch(loadMessagesData(params, search, isAdmin));
  }
  if (subTab === 'completedOrders') {
    return dispatch(loadCompletedOrdersData(params, search, isAdmin));
  }

  dispatch(fetchOrdersOrSalesRequest());
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10;
  const transitions = getAllTransitionsForEveryProcess();
  const orderTransitions =
    subTab === 'inquiries'
      ? transitions
      : tab === 'orders'
      ? transitions.filter(
          t =>
            t !== 'transition/inquire' &&
            t !== 'transition/expire-payment' &&
            t !== 'transition/mark-received' &&
            t !== 'transition/mark-received-skip-payment' &&
            t !== 'transition/cancel-skip-payment'
        )
      : transitions.filter(
          t =>
            t !== 'transition/inquire' &&
            t !== 'transition/expire-payment' &&
            t !== 'transition/mark-delivered' &&
            t !== 'transition/mark-delivered-skip-payment' &&
            t !== 'transition/mark-received' &&
            t !== 'transition/mark-received-skip-payment' &&
            t !== 'transition/cancel-skip-payment'
        );
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  try {
    let numberOfPages = 1;

    var allTransactions;
    var allIncluded;

    // Step 2: Fetch transactions in batches
    if (isAdmin && tab === 'sales' && subTab === 'transactions') {
      const apiQueryParams = {
        lastTransitions: orderTransitions,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'messages',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };
      const transactionBatches = await fetch('/api/get-all-transactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(apiQueryParams),
      })
        .then(response => response.json())
        .then(async res => {
          return res;
        })
        .catch(e => {
          console.err('transaction error', e);
          dispatch(fetchSelectedAdminUsersError(storableError(e)));
        });
      const batchTransactions = transactionBatches.data.data;
      allTransactions = batchTransactions.filter(
        txb => new Date(txb.attributes.protectedData?.orderData?.getbyDate) >= today
      );
      allIncluded = transactionBatches.data.included;
    } else {
      const numberOfPagesQuery = {
        only: onlyFilter,
        lastTransitions: orderTransitions,
        // include: [
        //   'customer',
        //   'customer.profileImage',
        //   'provider',
        //   'provider.profileImage',
        //   'listing',
        //   'listing.currentStock',
        //   'messages',
        //   'listing.images',
        // ],
        // 'fields.transaction': [
        //   'processName',
        //   'lastTransition',
        //   'lastTransitionedAt',
        //   'transitions',
        //   'payinTotal',
        //   'payoutTotal',
        //   'lineItems',
        //   'metadata',
        //   'protectedData',
        // ],
        // 'fields.listing': [
        //   'title',
        //   'availabilityPlan',
        //   'publicData.listinglocation',
        //   'publicData.location',
        // ],
        // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
        include: [],
        page: 1,
        perPage: 1,
      };

      const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
      numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;

      if (numberOfPages === 0) {
        dispatch(addMarketplaceEntities(emptyResponse));
        dispatch(fetchOrdersOrSalesSuccess(emptyResponse));
        return emptyResponse;
      }
      const getTransactions = async page => {
        const apiQueryParams = {
          only: onlyFilter,
          lastTransitions: orderTransitions,
          include: [
            'customer',
            'customer.profileImage',
            'provider',
            'provider.profileImage',
            'listing',
            'listing.currentStock',
            'messages',
            'listing.images',
          ],
          'fields.transaction': [
            'processName',
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'lineItems',
            'metadata',
            'protectedData',
          ],
          'fields.listing': [
            'title',
            'availabilityPlan',
            'publicData.listinglocation',
            'publicData.location',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          page: page + 1,
          perPage: 100,
        };
        return await sdk.transactions.query(apiQueryParams);
      };

      const transactionBatches = await mapLimit(
        [...Array(numberOfPages).keys()],
        5,
        getTransactions
      );
      // Step 3: Combine transactions and included data
      allTransactions = transactionBatches
        .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
        .filter(txb => new Date(txb.attributes.protectedData.orderData?.getbyDate) >= today);

      allIncluded = transactionBatches.reduce((a, txb) => a.concat(txb?.data?.included || []), []);
    }

    // Step 4: Sort transactions based on sortFilter
    if (sortFilter === 'getbyDate') {
      allTransactions.sort((a, b) => {
        const dateA = new Date(a?.attributes?.protectedData?.orderData?.getbyDate);
        const dateB = new Date(b?.attributes?.protectedData?.orderData?.getbyDate);
        return dateA - dateB;
      });
    } else {
      allTransactions.sort((a, b) => {
        const dateA = getSkipPaymentDate(a) || getConfirmPaymentDate(a);
        const dateB = getSkipPaymentDate(b) || getConfirmPaymentDate(b);
        return dateB - dateA; // Descending by "confirm-payment" date
      });
    }

    // Step 5: Paginate sorted transactions
    const paginatedTransactions = allTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(allTransactions.length / perPage);
    const finalResponse = {
      status: 200,
      statusText: '',
      data: {
        data: allTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: perPage,
          totalItems: allTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));
    dispatch(fetchOrdersOrSalesSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchOrdersOrSalesError(storableError(error)));
    throw error;
  }
};

export const getSelectedAdminSellers = params => async (dispatch, getState, sdk) => {
  dispatch(fetchSelectedAdminUsersRequest());
  const data = {};

  fetch('/api/get-selected-admin-sellers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(async res => {
      dispatch(fetchSelectedAdminUsersSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchSelectedAdminUsersError(storableError(e)));
    });
};

export const getSellerListings = email => async (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());

  sdk.listings
    .query({
      authorId: email,
      include: ['author'],
      'fields.author': ['publicData.continuousDays'],
    })
    .then(res => {
      // Filter listings to keep only those with state 'published'
      const publishedListings = res.data.data.filter(
        listing => listing.attributes.state === 'published'
      );

      // Create a new response structure with filtered listings
      const filteredResponse = {
        ...res.data,
        data: publishedListings,
      };
      dispatch(fetchListingSuccess(filteredResponse));
    })
    .catch(e => {
      console.error(e);
      dispatch(fetchListingError(storableError(e)));
    });
};

export const getSellersListingCount = params => async (dispatch, getState, sdk) => {
  dispatch(getListingCountRequest());
  const mainData = [];

  let dat = { pageNumber: 1 };

  fetch('/api/get-all-listings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(dat),
  })
    .then(response => response.json())
    .then(async resp => {
      dispatch(getListingCountSuccess(resp.data));

      const totalPages = resp.data.meta.totalPages;

      let count = 2;
      // mainData=[...resp.data.data];

      while (count <= totalPages) {
        dat = { pageNumber: count };
        fetch('/api/get-all-listings', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(dat),
        })
          .then(response => response.json())
          .then(async resp => {
            const state = getState();
            const listings = state.InboxPage.listings.data;
            const included = state.InboxPage.listings.included;
            resp.data.data = [...listings, ...resp.data.data];
            resp.data.included = [...included, ...resp.data.included];
            dispatch(getListingCountSuccess(resp.data));
          })
          .catch(e => {
            dispatch(getListingCountError(storableError(e)));
          });

        count += 1;
      }
    })
    .catch(e => {
      dispatch(getListingCountError(storableError(e)));
    });
};

export const initiateTransferOrder = (
  originalTransaction,
  edittedValues,
  listing,
  currentSelectedSeller
) => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const handleError = e => {
    dispatch(confirmPaymentError(storableError(e)));
  };

  const edittedVals = originalTransaction.values;
  const txId = originalTransaction?.tx?.id;

  // Extract edited values from edittedVals
  const quantity_ = Number(edittedVals?.quantity);
  const size_ = edittedVals?.size; // Map to attribute1OrderForm
  const flavor_ = edittedVals?.flavor; // Map to attribute2OrderForm
  const additionalAttribute_ = edittedVals?.additionalAttribute; // Map to attribute4OrderForm
  const deliveryOn_ = edittedVals?.deliveryDate?.date;
  const deliveryMethod_ = edittedVals?.deliveryMethod;
  const deliveryAddress_ = edittedVals?.shippingAddress?.selectedPlace?.address;
  const addressLine2_ = edittedVals?.addressLine2;
  const customShippingCost_ = edittedVals?.selectedShippingOption; // Use for shipping cost
  const specialInstructions_ = edittedVals?.additionalPersonalization;
  const freeShippingEnabled = listing?.attributes?.publicData?.freeShippingEnabled || false;
  const imageUploadData_ = edittedVals?.imageUploadData || [];
  const checkoutNotes_ = edittedVals?.checkoutNotes;

  const customShippingInfo = {
    isFound: deliveryMethod_ === 'shipping', // True if deliveryMethod is shipping
    customShippingCost: freeShippingEnabled ? 0 : customShippingCost_,
    isCustomLoading: false,
  };

  // Original order data
  let orderData = originalTransaction?.tx?.attributes?.protectedData?.orderData || {};
  let lastMileDeliveryDetails =
    originalTransaction?.tx?.attributes?.protectedData?.lastMileDeliveryDetails || {};
  let shippingDetails = originalTransaction?.tx?.attributes?.protectedData?.shippingDetails || {};

  const customerDetailsOg =
    originalTransaction?.tx?.attributes?.protectedData?.customerDetails || {};

  // Construct customerDetails in the desired format
  const customerDetails = {
    email:
      shippingDetails?.email || lastMileDeliveryDetails?.email || customerDetailsOg?.email || '',
    name: shippingDetails?.name || lastMileDeliveryDetails?.name || customerDetailsOg?.name || '',
    lastname:
      shippingDetails?.lastname ||
      lastMileDeliveryDetails?.lastname ||
      customerDetailsOg?.lastname ||
      '',
    phoneNumber:
      shippingDetails?.phoneNumber ||
      lastMileDeliveryDetails?.phoneNumber ||
      customerDetailsOg?.phoneNumber ||
      '',
  };

  // Resolve quantity, listing ID, and delivery method
  const quantity = quantity_ !== undefined ? quantity_ : orderData.quantity;

  const listingId = listing.id.uuid;
  const deliveryMethod =
    deliveryMethod_ !== undefined
      ? deliveryMethod_
      : originalTransaction?.tx?.attributes?.protectedData?.deliveryMethod;

  orderData['quantity'] = quantity;

  orderData['deliveryMethod'] = deliveryMethod;

  orderData['attribute1OrderForm'] = size_;

  orderData['attribute2OrderForm'] = flavor_;

  orderData['attribute4OrderForm'] = additionalAttribute_;

  orderData['getbyDate'] = new Date(deliveryOn_).toDateString(); // Format as string

  if (imageUploadData_?.urls !== undefined || imageUploadData_?.urls.length > 0) {
    orderData['imageUploadData'] = imageUploadData_;
  }

  orderData['addPersonalization'] = specialInstructions_ || null;

  orderData['checkoutNotes'] = checkoutNotes_ || null;

  if (deliveryAddress_ !== undefined && deliveryAddress_ !== '') {
    const addressParts = deliveryAddress_.split(',');
    const line1 = addressParts[0]?.trim();
    const line2 = addressLine2_ || '';
    const city = addressParts[1]?.trim();
    const stateZip = addressParts[2]?.trim().split(' ');
    const state = stateZip[0] || '';
    const postalCode = stateZip[1] || '';

    orderData['deliveryDestination'] = {
      predictions: [],
      search: deliveryAddress_,
      selectedPlace: {
        address: deliveryAddress_,
        bounds: edittedVals?.shippingAddress?.selectedPlace?.bounds || {},
        origin: edittedVals?.shippingAddress?.selectedPlace?.origin || {},
      },
    };

    shippingDetails['address'] = {
      line1,
      line2,
      city,
      state,
      postalCode,
      country: 'US', // Default to US if not provided
    };

    if (customShippingCost_ !== undefined) {
      shippingDetails['shippingCost'] = customShippingCost_;
    }
  }

  // Update lastMileDeliveryDetails
  if (deliveryMethod === 'lastMileDelivery') {
    const lastMileAddress = deliveryAddress_ || lastMileDeliveryDetails?.address || {};
    const addressParts = lastMileAddress.split(',');
    const line1 = addressParts[0]?.trim();
    const city = addressParts[1]?.trim();
    const stateZip = addressParts[2]?.trim().split(' ');
    const state = stateZip[0] || '';
    const postalCode = stateZip[1] || '';

    lastMileDeliveryDetails['address'] = {
      line1,
      city,
      state,
      postalCode,
      country: 'US', // Default to US if not provided
    };
  }
  // Log final payload for debugging
  const payload = {
    isSpeculative: false,
    orderData,
    bodyParams: {
      processAlias: 'default-purchase/release-1',
      transition: 'transition/skip-payment',
      params: {
        stockReservationQuantity: quantity,
        listingId: {
          _sdkType: 'UUID',
          uuid: listingId,
        },
        protectedData: {
          customerDetails,
          orderData,
          unitType: 'item',
          deliveryMethod,
          shippingDetails,
          lastMileDeliveryDetails,
          isTransferred: true,
        },
        customShippingInfo: customShippingInfo,
      },
    },
    queryParams: {
      include: ['booking', 'provider'],
      expand: true,
    },
  };

  // Initiate transaction
  initiatePrivileged(payload)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));

      // Get existing metadata (if available)
      let existingMetadata = originalTransaction?.tx?.attributes?.metadata || {};

      // Check if `transferOrderDetails` exists
      let transferOrderDetails = existingMetadata?.transferOrderDetails || [];
      // Append new seller details
      transferOrderDetails.push({
        sellerId: currentSelectedSeller?.id?.uuid,
        sellerName:
          currentSelectedSeller?.attributes?.profile?.firstName +
            ' ' +
            currentSelectedSeller?.attributes?.profile?.lastName || 'Unknown Seller',
        listingName: listing.attributes.title || 'Unknown Listing',
        transfertransactionId: order?.id?.uuid,
      });

      // Prepare payload with updated metadata
      let dat = {
        txId: txId,
        metadata: {
          ...existingMetadata,
          transferOrderDetails,
        },
      };
      fetch('/api/update-tx-meta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(dat),
      });

      let secondDat = {
        txId: order.id, // New transaction ID
        metadata: {
          mainTransactionId: txId.uuid, // Add mainTransactionId field
        },
      };

      //Update transaction
      fetch('/api/update-tx-meta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(secondDat),
      });
    })
    .catch(e => {
      console.error('Payment confirmation failed:', e);
      handleError(e);
    });
  return { success: true };
};
