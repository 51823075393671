import { fetchPageAssets } from '../../ducks/hostedAssets.duck';

export const ONBOARD_REQUEST = 'app/Checkout/ONBOARD_REQUEST';
export const ONBOARD_SUCCESS = 'app/Checkout/ONBOARD_SUCCESS';
export const ONBOARD_ERROR = 'app/Checkout/ONBOARD_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/Checkout/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/Checkout/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/Checkout/CONFIRM_PAYMENT_ERROR';

const initialState = {
  onboardError: null,
  onboardInProgress: false,
  confirmPaymentInProgress: false,
  confirmPaymmentError: null,
  transactionRes: [],
};

//Reducers
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CONFIRM_PAYMENT_REQUEST:
      return {
        ...state,
        confirmPaymentInProgress: true,
        confirmPaymmentError: null,
      };
    case CONFIRM_PAYMENT_SUCCESS:
      return { ...state, confirmPaymentInProgress: false };
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        confirmPaymentInProgress: false,
        confirmPaymmentError: payload,
      };

    case ONBOARD_REQUEST:
      return {
        ...state,
        onboardInProgress: true,
        onboardError: null,
      };
    case ONBOARD_SUCCESS:
      return { ...state, onboardInProgress: false, transactionRes: payload };
    case ONBOARD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        onboardInProgress: false,
        onboardError: payload,
      };

    default:
      return state;
  }
}

//------------------Action creators
export const onboardRequest = () => ({ type: ONBOARD_REQUEST });
export const onboardSuccess = response => ({
  type: ONBOARD_SUCCESS,
  payload: response,
});
export const onboardError = error => ({
  type: ONBOARD_ERROR,
  payload: error,
  error: true,
});

export const confirmPaymentRequest = () => ({ type: ONBOARD_REQUEST });
export const confirmPaymentSuccess = response => ({
  type: ONBOARD_SUCCESS,
  payload: response,
});
export const confirmPaymentError = error => ({
  type: ONBOARD_ERROR,
  payload: error,
  error: true,
});

export const getUnfinishedTransactions = params => (dispatch, getState, sdk) => {
  console.log('Fetching Inactive transactions  oooooooooooooo');
  const token = fetch('/api/get-transactions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(async data => {})
    .catch(function(error) {
      let edata = error.message;
      console.log('Error:', edata);
    });
};

export const loadData = (params, search) => dispatch => {
  //   const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  // return dispatch(fetchPageAssets(pageAsset, true));
};
